import { useEffect } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import moment from 'moment';
import AddressStatus from '../../../../components/Orders/AddressStatus';
import InstructionStatus from '../../../../components/Orders/InstructionStatus';
import { SmallTitle } from '../../../../components/Typography/Typography';
import OrderItemsTable from '../OrderItemsTable';
import { ReadOnlyDetailWrapper } from './DetailWrapper';
import { useAccounting } from '../../../../contexts/accounting';
import { useFlags } from 'launchdarkly-react-client-sdk';

type OrderDetailTitleRowProps = {
  showEditedFieldStatuses?: boolean;
  order: any;
};

export const OrderDetailFields = ({
  showEditedFieldStatuses = true,
  order,
}: OrderDetailTitleRowProps) => {
  const { getCurrentAccountingConnection, currentAccountingConnection } =
    useAccounting();
  const flags = useFlags();

  useEffect(() => {
    if (currentAccountingConnection) {
      return;
    }

    getCurrentAccountingConnection(() => {});
  }, []);

  console.log(order);

  return (
    <VStack spacing="6" pt="6" alignItems="left">
      {order.delivery_date && (
        <ReadOnlyDetailWrapper
          label="Delivery date"
          content={moment(order.delivery_date).format('dddd, D MMMM YYYY')}
          subText={
            order.initial_delivery_date &&
            order.delivery_date &&
            order.delivery_date !== order.initial_delivery_date
              ? moment(order.initial_delivery_date).format('ddd D MMMM YYYY')
              : undefined
          }
        />
      )}
      {order.num_boxes && flags.deliveryLabels && (
        <ReadOnlyDetailWrapper label="Boxes" content={order.num_boxes} />
      )}
      {(order.delivery_address || order.initial_delivery_address) && (
        <ReadOnlyDetailWrapper
          label="Delivery address"
          content={order.delivery_address || '-'}
          subText={
            order.delivery_address !== order.initial_delivery_address
              ? order.initial_delivery_address || 'None supplied'
              : undefined
          }
        >
          {showEditedFieldStatuses && order.delivery_address_status && (
            <AddressStatus mt="1.5" status={order.delivery_address_status} />
          )}
        </ReadOnlyDetailWrapper>
      )}
      {(order.delivery_instructions || order.initial_delivery_instructions) && (
        <ReadOnlyDetailWrapper
          label="Delivery instructions"
          content={order.delivery_instructions || '-'}
          subText={
            order.delivery_instructions !== order.initial_delivery_instructions
              ? order.initial_delivery_instructions || 'None supplied'
              : undefined
          }
        >
          {showEditedFieldStatuses && order.delivery_instructions_status && (
            <InstructionStatus
              mt="1.5"
              status={order.delivery_instructions_status}
            />
          )}
        </ReadOnlyDetailWrapper>
      )}
      {currentAccountingConnection?.key === 'mountain_stream' && (
        <ReadOnlyDetailWrapper
          label="Address code"
          tooltip="This is required for Mountain Stream. You can set a default on the customer so you don't need to fill this field for every order."
          content={
            order.connector_fields && order.connector_fields.address_code
              ? order.connector_fields.address_code
              : order.customer_company.connector_fields &&
                order.customer_company.connector_fields.default_address_code
              ? order.customer_company.connector_fields.default_address_code
              : '-'
          }
        />
      )}
      {order.message && (
        <ReadOnlyDetailWrapper label="Message" content={order.message} />
      )}
      {order.customer_company.notes && (
        <ReadOnlyDetailWrapper
          label="Customer notes"
          content={order.customer_company.notes}
        />
      )}
      <Box>
        <SmallTitle mb="8px" color="gray.400">
          Order
        </SmallTitle>
        <OrderItemsTable orderItems={order.order_items} isEditable={false} />
      </Box>
    </VStack>
  );
};

export default OrderDetailFields;
