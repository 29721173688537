import { observable, action, makeObservable } from 'mobx';
import { Instance } from 'mobx-state-tree';
import Order from '../../../models/Order';
import OrderConnectorFields from '../../../models/OrderConnectorFields';
import Customer from '../../../models/Customer';
import OrderItem from '../../../models/OrderItem';
import Product from '../../../models/Product';

export class EditableOrderStore {
  user: any = [];
  @observable editableOrder?: Instance<typeof Order>;

  constructor(user?: any, order?: any) {
    this.user = user;
    this.setEditableOrder(order);
    makeObservable(this);
  }

  @action setEditableOrder = (order: any) => {
    if (order) {
      this.editableOrder = Order.create({
        id: 'edit',
        customer: Customer.create({
          id: order.customer_company.id,
          name: order.customer_company.name,
          logo_url: order.customer_company.logo_url,
        }),
        deliveryDate: new Date(order.delivery_date || null),
        delivery_address: order.delivery_address,
        delivery_instructions: order.delivery_instructions,
        purchase_order_number: order.purchase_order_number,
        connector_fields: OrderConnectorFields.create(
          order.connector_fields || {},
        ),
        num_boxes: order.num_boxes,
        items: order.order_items.map((order_item: any) => {
          return OrderItem.create({
            id: order_item.id,
            is_added: order_item.is_added,
            notes: order_item.notes,
            supplier_note: order_item.supplier_note,
            amount: Number(order_item.amount),
            initial_amount: Number(order_item.initial_amount),
            unit: order_item.unit,
            initial_unit: order_item.initial_unit,
            buyable: {
              type: 'product',
              product: Product.create(order_item.buyable.product),
            },
          });
        }),
      });
    } else {
      this.editableOrder = undefined;
    }
  };
}

export default new EditableOrderStore();
