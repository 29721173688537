import { action, computed, makeObservable, observable } from 'mobx';
import * as Sentry from '@sentry/browser';

import { api } from '../api';
import { RootStore } from './rootStore';
import { PaginationStore } from './paginationStore';
import { Instance } from 'mobx-state-tree';

import Ingredient, { IngredientProps } from '../models/Ingredient';

interface IngredientsListInterface {
  [id: number]: Instance<typeof Ingredient>;
}

export class IngredientsStore {
  user: any = [];
  pageLimit = 20;

  @observable rootStore?: RootStore;

  @observable dataIsLoading: boolean = true;
  @observable currentSearchQuery: string = '';
  @observable currentPage: number = 1;

  @observable pagination = new PaginationStore();
  @observable totalCount?: number;
  @observable totalPages: number = 0;
  @observable nextPage?: number;
  @observable previousPage?: number;

  @observable ingredientsList: IngredientsListInterface = {};
  @observable ingredientsIndex: (string | number | null)[] = [];
  @observable currentIngredientId?: number;

  @observable saveButtonIsLoading: boolean = false;

  constructor(rootStore?: RootStore, user?: any) {
    if (rootStore) {
      this.rootStore = rootStore;
    }
    this.user = user;
    makeObservable(this);
  }

  @computed
  get ingredients() {
    return this.ingredientsIndex.map((key) => {
      if (typeof key === 'number') return this.ingredientsList[key];
    });
  }

  @computed
  get currentIngredient(): any {
    if (this.currentIngredientId) {
      return this.ingredientsList[this.currentIngredientId];
    } else {
      return null;
    }
  }

  @action
  setIngredients = (ingredients: any) => {
    this.pagination.setFromResponse(ingredients);
    this.ingredientsIndex = [];
    ingredients.results.forEach(
      (ingredient: IngredientProps, index: number) => {
        if (!this.ingredientsList[ingredient.id]) {
          this.ingredientsList[ingredient.id] = Ingredient.create({
            id: ingredient.id,
            name: ingredient.name,
            unit: ingredient.unit,
            conversion_factor: parseFloat(ingredient.conversion_factor),
          });
        }
        this.ingredientsIndex[index] = ingredient.id;
      },
    );
  };

  @action
  clearCurrentIngredient = () => {
    if (this.currentIngredient) {
      this.currentIngredient.setIsCurrent(false);
    }
    this.currentIngredientId = undefined;
  };
  @action
  setCurrentIngredient = (ingredient?: IngredientProps) => {
    if (this.currentIngredient) {
      this.currentIngredient.setIsCurrent(false);
    }

    if (ingredient) {
      const ingredientObject = this.ingredientsList[ingredient.id];
      if (ingredientObject) {
        ingredientObject.addData(ingredient);
        ingredientObject.setIsCurrent(true);
      } else {
        const newIngredient = Ingredient.create({
          id: ingredient.id,
          name: ingredient.name,
          unit: ingredient.unit,
          conversion_factor: parseFloat(ingredient.conversion_factor),
        });
        newIngredient.addData(ingredient);
        newIngredient.setIsCurrent(true);

        this.ingredientsList[ingredient.id] = newIngredient;
      }
      this.currentIngredientId = ingredient.id;
    } else {
      this.currentIngredientId = undefined;
    }
  };
  @action setdataIsLoading = (dataIsLoading: boolean) =>
    (this.dataIsLoading = dataIsLoading);
  @action setCurrentSearchQuery = (query: string) => {
    this.currentSearchQuery = query;
  };
  @action setCurrentPage = (value: number) => (this.currentPage = value);
  @action setSaveButtonIsLoading = (value: boolean) =>
    (this.saveButtonIsLoading = value);

  @action clearFilters = () => {
    this.currentSearchQuery = '';
  };

  @action
  getIngredients = () => {
    return new Promise((resolve, reject) => {
      const searchParam = (this.currentSearchQuery && `&q=${this.currentSearchQuery}`) || '';
      const path = `/v4/ingredients?limit=${this.pageLimit}&page=${this.currentPage}${searchParam}`;
      this.setdataIsLoading(true);
      
      api
        .get(path)
        .then(async (response) => {
          const data = await response.json();
          if (response.ok && response.status !== 401) {
            this.setIngredients(data);
            resolve(data);
          }
          reject(data);
          this.setdataIsLoading(false);
        })
        .catch((error) => reject(error));
    });
  };

  @action
  getIngredient = (ingredientId: string) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/v4/ingredients/${ingredientId}`)
        .then(async (response) => {
          this.setCurrentIngredient(await response.json());
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  @action
  updateIngredient = (
    ingredient: Instance<typeof Ingredient>,
    values: any,
  ) => {
    return new Promise((resolve, reject) => {
      api
        .put(
          `/v4/ingredients/${ingredient.id}`,
          JSON.stringify({
            ingredient: { ...values },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.setCurrentIngredient(data);
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  @action
  createIngredient = (values: any) => {
    return new Promise((resolve, reject) => {
      api
        .post(
          `/v4/ingredients`,
          JSON.stringify({
            ingredient: { ...values },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.pagination.addToCount();
            this.setCurrentIngredient(data);
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  @action deleteIngredient = (ingredient: Instance<typeof Ingredient>) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/v4/ingredients/${ingredient.id}`)
        .then(async (response) => {
          if (response.ok) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
